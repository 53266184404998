<template></template>

<script>
import { Hub, Logger } from "aws-amplify";
const alex = new Logger("Alexander_the_auth_watcher");

export default {
  name: "SignIn",
  data() {
    return {};
  },
  created() {
    this.authListen();
  },
  methods: {
    authListen() {
      alex.onHubCapsule = async capsule => {
        switch (capsule.payload.event) {
          case "signIn":
            let user = await this.$Amplify.Auth.currentAuthenticatedUser();
            this.$store.commit("user/setUser", user);
            this.$router.push("/");
            break;
          case "signUp":
            alex.error("user signed up");
            break;
          case "signOut":
            alex.error("user signed out");
            break;
          case "signIn_failure":
            alex.error("user sign in failed");
            break;
          case "configured":
            alex.error("the Auth module is configured");
        }
      };
      Hub.listen("auth", alex);
    }
  }
};
</script>

<style lang="scss" scoped>
.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>
